import Box from '@components/elements/Box';

const Description = ({ description, benefits }) => {
  if (!description?.enabled) {
    return null;
  }

  return (
    <div className="container" tw="my-4 sm:my-10 md:my-16">
      <Box>
        <h2>{description.title}</h2>
        <p
          tw="mb-5"
          dangerouslySetInnerHTML={{
            __html: description.content,
          }}
        />
        <div tw="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
          {(benefits['hydra:member'] ?? [])
            .sort((a, b) => a.no - b.no)
            .map(benefit => (
              <div tw="flex flex-col md:items-center" key={benefit.id}>
                <img tw="w-20" src={benefit.image} />
                <h3 tw="mt-3.5 text-lg">{benefit.title}</h3>
                <p>{benefit.content}</p>
              </div>
            ))}
        </div>
      </Box>
    </div>
  );
};

export default Description;
