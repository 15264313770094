import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';

import SeoCms from '@components/modules/Seo/SeoCms';
import CUSTOM_SEO_SETTINGS_KEYS from '@constants/customSeoSettingsKeys';
import useHomePage from '@hooks/queries/useHomePage';

import { HomePageContextProvider } from './HomePage.context';
import HomePageView from './HomePage.view';

const HomePage = ({ seo }) => {
  const { t } = useTranslation();
  const { data: { contentEnabled, content } = {} } = useHomePage();

  return (
    <Fragment>
      <SeoCms
        pageName={t('$*homePage.pageName', 'Strona główna')}
        seoProps={seo ?? {}}
        customSeoSettingsKey={CUSTOM_SEO_SETTINGS_KEYS.HOME_PAGE}
      />
      <HomePageContextProvider>
        {contentEnabled ? (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        ) : (
          <HomePageView />
        )}
      </HomePageContextProvider>
    </Fragment>
  );
};

export default HomePage;
