import { css } from '@emotion/react';
import tw from 'twin.macro';

const styles = {
  navigation: ({ prev = false, next = false }) => css`
    ${tw`absolute transform -translate-y-1/2 top-1/2 text-gray-2 hocus:(text-primary)`}
    ${prev && tw`left-0 -rotate-90 -translate-x-full`}
    ${next && tw`right-0 rotate-90 translate-x-full`}

    .swiper-button-disabled {
      ${tw`opacity-0`}
    }
  `,
  navigationIcon: tw`w-5 fill-current`,
};

export default styles;
