// import Swiper core and required modules
import { Fragment, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import SwiperCore, { A11y, Keyboard, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import tw from 'twin.macro';

import ChevronUpIcon from '@assets/icons/ChevronUpIcon';

import carouselStyles from './Carousel.styles';

// install Swiper modules
SwiperCore.use([A11y, Keyboard, Navigation]);

const Carousel = ({
  children,
  options = {},
  navigation = {},
  swiperRef,
  isDisabledCustomNavigation = false,
  navigationPrevRef,
  navigationNextRef,
  styles,
  onNavigationNextClick = () => {},
  ...restProps
}) => {
  const [visibleSlidesIndexes, setVisibleSlidesIndexes] = useState([]);

  let initSwiperRef = useRef(null);
  let initNavigationPrevRef = useRef(null);
  let initNavigationNextRef = useRef(null);

  swiperRef ??= initSwiperRef;
  navigationPrevRef ??= initNavigationPrevRef;
  navigationNextRef ??= initNavigationNextRef;

  const swiperOptions = {
    spaceBetween: 50,
    slidesPerView: 3,
    navigation: {
      prevEl: navigationPrevRef.current,
      nextEl: navigationNextRef.current,
    },
    ...options,
  };

  useEffect(() => {
    handleVisibleSlidesIndexesChange();
  }, []);

  const handleVisibleSlidesIndexesChange = () => {
    if (swiperRef?.current) {
      const swiper = swiperRef.current.swiper;

      const slidesPerGroup = swiper?.params?.slidesPerGroup ?? 1;
      let newVisibleSlidesIndexes = [...(swiper?.visibleSlidesIndexes ?? [])];

      if (newVisibleSlidesIndexes?.length < 1) return;

      for (let index = 0; index < slidesPerGroup; index++) {
        let nextTopIndex =
          newVisibleSlidesIndexes[newVisibleSlidesIndexes?.length - 1] + 1;
        let nextBottomIndex = newVisibleSlidesIndexes[0] - 1;

        newVisibleSlidesIndexes = [
          nextBottomIndex,
          ...newVisibleSlidesIndexes,
          nextTopIndex,
        ];
      }

      setVisibleSlidesIndexes(newVisibleSlidesIndexes);
    }
  };

  useEffect(() => {
    if (
      swiperRef?.current?.swiper?.params?.navigation?.prevEl === null ||
      swiperRef?.current?.swiper?.params?.navigation?.nextEl === null
    ) {
      // Override prevEl & nextEl now that refs are defined
      swiperRef.current.swiper.params.navigation.prevEl =
        navigationPrevRef.current;
      swiperRef.current.swiper.params.navigation.nextEl =
        navigationNextRef.current;

      // Re-init navigation
      swiperRef.current.swiper.navigation.destroy();
      swiperRef.current.swiper.navigation.init();
      swiperRef.current.swiper.navigation.update();
    }
  }, [navigationNextRef, navigationPrevRef, swiperRef]);

  return (
    <div
      className={clsx(
        restProps?.className && `${restProps?.className}-wrapper`
      )}
      css={[tw`relative`, styles]}
    >
      <Swiper
        {...(swiperRef ? { ref: swiperRef } : {})}
        {...restProps}
        {...swiperOptions}
        onSlideChange={handleVisibleSlidesIndexesChange}
      >
        {children(SwiperSlide, visibleSlidesIndexes)}
      </Swiper>
      {!isDisabledCustomNavigation && (
        <Fragment>
          <div
            ref={navigationPrevRef}
            role="button"
            css={carouselStyles.navigation({ prev: true })}
            {...navigation?.prev}
          >
            <ChevronUpIcon css={carouselStyles.navigationIcon} />
          </div>
          <div
            ref={navigationNextRef}
            role="button"
            css={carouselStyles.navigation({ next: true })}
            {...navigation?.next}
            onClick={() => onNavigationNextClick(visibleSlidesIndexes)}
          >
            <ChevronUpIcon css={carouselStyles.navigationIcon} />
          </div>
        </Fragment>
      )}
    </div>
  );
};

Carousel.displayName = 'Carousel';

export default Carousel;
