import { useRouter } from 'next/router';

import { createContext, useContext } from 'react';

import ROUTE_URLS from '@constants/routeUrls';
import {
  selectModuleConfigClientPanel,
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useBasketMethods from '@hooks/useBasketMethods';
import useDishes from '@hooks/useDishes';
import { selectOrderForm, useOrderFormSelector } from '@hooks/useOrderForm';

const HomePageContext = createContext([{}, () => {}]);

const HomePageContextProvider = ({ children }) => {
  const router = useRouter();
  const { currencySymbol } = useAppConfigSelector(selectMultinational);

  const {
    allowVariantAutoScroll,
    dishImageBackground,
    dishImageBackgroundEnabled,
    dishImagePlaceholder,
    '@resources': resources,
  } = useAppConfigSelector(selectModuleConfigClientPanel);

  const dishesQuery = useDishes({
    partial: false,
    page: 1,
    itemsPerPage: 10,
    properties: ['nameForClient', 'clientImage', 'rateAVG'],
    'order[rate]': 'DESC',
    'exist[rate]': true,
  });

  const { diets: dietsSlides = [], priceList } =
    useOrderFormSelector(selectOrderForm);

  const { handleUpdateBasketDiet } = useBasketMethods();

  const orderDiet = async diet => {
    await handleUpdateBasketDiet({
      diet: diet?.['@id'],
      variant: diet?.variants?.[0]?.['@id'],
      calorific: diet?.variants?.[0]?.['calories']?.[0]?.['@id'],
      scrollIntoVariants: allowVariantAutoScroll,
    });
    router.push(ROUTE_URLS.NEW_ORDER_FORM);
  };

  const value = {
    currencySymbol,
    dishesQuery,
    dietsSlides,
    priceList,
    dishImageBackground,
    dishImageBackgroundEnabled,
    dishImagePlaceholder,
    resources,
    orderDiet,
  };

  return (
    <HomePageContext.Provider value={value}>
      {children}
    </HomePageContext.Provider>
  );
};

const useHomePageContext = () => useContext(HomePageContext);

export { HomePageContextProvider, useHomePageContext };
