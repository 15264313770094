import { dehydrate, QueryClient } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';

import withCustomErrorBoundary from '@components/HOC/withCustomErrorBoundary';
import ROUTE_URLS from '@constants/routeUrls';
import { getHomePage } from '@hooks/queries/useHomePage';
import getPageErrorProps from '@utils/getPageErrorProps';
import getServerSideTranslations from '@utils/getServerSideTranslations';
import HomePage from '@views/HomePage/HomePage.container';

export const getServerSideProps = async ({ res, locale }) => {
  try {
    const queryClient = new QueryClient();
    const homePageData = await getHomePage();

    await queryClient.prefetchQuery({
      queryKey: ['homePage'],
      queryFn: () => homePageData,
    });

    const isDisabled = !(homePageData?.enabled || homePageData?.contentEnabled);

    if ((isEmpty(homePageData) || isDisabled) && !res?.headersSent) {
      return {
        redirect: {
          destination: locale
            ? `/${locale}${ROUTE_URLS.NEW_ORDER_FORM}`
            : ROUTE_URLS.NEW_ORDER_FORM,
          permanent: false,
        },
      };
    }

    return {
      props: {
        ...(await getServerSideTranslations(locale)),
        bodyClassName: 'page--home',
        locale,
        dehydratedState: dehydrate(queryClient),
      },
    };
  } catch (error) {
    return await getPageErrorProps({ error, locale });
  }
};

export default withCustomErrorBoundary(HomePage);
