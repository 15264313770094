import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Trans } from 'next-i18next';

import Box from '@components/elements/Box';
import Carousel from '@components/elements/Carousel/Carousel';
import ProductCard from '@components/elements/ProductCard/ProductCard';
import useMobileDetect from '@hooks/useMobileDetect';

import { useHomePageContext } from './HomePage.context';

const Diets = ({ diets }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const currentDevice = useMobileDetect();
  const isMobile = currentDevice.isMobile();
  const { currencySymbol, dietsSlides, priceList, orderDiet } =
    useHomePageContext();

  if (!diets?.enabled) {
    return null;
  }

  return (
    <div className="container" tw="my-4 sm:my-10 md:my-16">
      <Box>
        <h2>{diets.title}</h2>
        <p
          tw="mb-8"
          dangerouslySetInnerHTML={{
            __html: diets.content,
          }}
        />
        <Carousel
          className="swiper--home-page"
          options={{
            slidesPerView: 'auto',
            spaceBetween: 24,
            allowTouchMove: isMobile,
            ...(!isMobile
              ? {
                  keyboard: {
                    enabled: true,
                    onlyInViewport: false,
                  },
                }
              : {}),
            breakpoints: {
              480: {
                slidesPerView: 2,
                slidesPerGroup: 2,
              },
              992: {
                slidesPerView: 3,
                slidesPerGroup: 3,
              },
              1200: {
                slidesPerView: 3.5,
                slidesPerGroup: 3,
              },
              1400: {
                slidesPerView: 4.5,
                slidesPerGroup: 4,
              },
            },
          }}
        >
          {Slide => {
            return dietsSlides.map(diet => {
              const prices = Object.entries(priceList.indexedByKey).filter(
                ([index]) => index.split('_')[0] == diet.id
              );

              const smallestPrice = Math.min(
                ...prices.map(([, price]) => price.standard)
              );
              return (
                <Slide key={diet?.['@id']}>
                  <ProductCard
                    {...{
                      homePage: true,
                      image: diet?.clientImage,
                      anchorLink: `/diets/${diet?.id}`,
                      anchorText: t(
                        '$*dietPageConfigurator.dietDetails',
                        'Szczegóły diety'
                      ),
                      buttonText: (
                        <Trans
                          i18nKey="$*commmon.homePage.dietsPresentation.ButtonText"
                          defaults="Zamów dietę już od: {{ price }} {{ currencySymbol }} / dzień"
                          values={{
                            price: smallestPrice,
                            currencySymbol,
                          }}
                          components={[<span key="0" tw="font-bold" />]}
                        />
                      ),
                      productName: diet?.name,
                      pricePrefix: t('$*common.from', 'od'),
                      productPrice: undefined,
                      onCardClick: () => router.push(`/diets/${diet?.id}`),
                      onButtonClick: () => {
                        orderDiet(diet);
                      },
                    }}
                  />
                </Slide>
              );
            });
          }}
        </Carousel>
      </Box>
    </div>
  );
};

export default Diets;
