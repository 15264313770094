import { ENDPOINT } from '@constants/endpoint';
import useInfiniteQueryMiddleware from '@hooks/useInfiniteQueryMiddleware';
import axios from '@utils/axiosInstance';

export const getDishes = async params => {
  const response = await axios.get(ENDPOINT.DISHES, { params });

  return response?.data['hydra:member'] ?? {};
};

const useDishes = params => {
  const query = useInfiniteQueryMiddleware(['dishes'], ENDPOINT.DISHES, {
    ...params,
  });

  return query;
};

export default useDishes;
