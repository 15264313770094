import { Fragment } from 'react';

import Banner from '@components/elements/Banner/Banner';
import useHomePage from '@hooks/queries/useHomePage';

import BestDishes from './BestDishes';
import Description from './Description';
import Diets from './Diets';

const HomePageView = () => {
  const {
    data: {
      bannerOne,
      bannerTwo,
      bannerThree,
      bannerFour,
      description,
      diets,
      bestDishes,
      benefits,
    } = {},
  } = useHomePage();

  return (
    <Fragment>
      <div tw="-mt-8 mb-6 sm:mb-16 md:mb-24">
        <Banner
          banner={{
            enable: bannerOne?.enabled,
            title: bannerOne?.heading,
            description: bannerOne?.content,
            imageDesktop: bannerOne?.desktop,
            imageMobile: bannerOne?.mobile,
          }}
        ></Banner>
      </div>

      <Description {...{ description, benefits }} />
      <Diets diets={diets} />
      <BestDishes bestDishes={bestDishes} />

      <Banner
        banner={{
          enable: bannerTwo?.enabled,
          title: bannerTwo?.heading,
          description: bannerTwo?.content,
          imageDesktop: bannerTwo?.desktop,
          imageMobile: bannerTwo?.mobile,
        }}
      />

      <Banner
        banner={{
          enable: bannerThree?.enabled,
          title: bannerThree?.heading,
          description: bannerThree?.content,
          imageDesktop: bannerThree?.desktop,
          imageMobile: bannerThree?.mobile,
        }}
      />

      <div tw="-mb-8 sm:-mb-12 md:-mb-16 lg:-mb-24">
        <Banner
          banner={{
            enable: bannerFour?.enabled,
            title: bannerFour?.heading,
            description: bannerFour?.content,
            imageDesktop: bannerFour?.desktop,
            imageMobile: bannerFour?.mobile,
          }}
        />
      </div>
    </Fragment>
  );
};

export default HomePageView;
