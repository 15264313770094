import { useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/axiosInstance';

export const getHomePage = async () => {
  const response = await axios.get(ENDPOINT.HOME_PAGE, {
    withToken: false,
  });

  return response?.data ?? {};
};

const useHomePage = (options = {}) =>
  useQuery({
    queryKey: ['homePage'],
    queryFn: getHomePage,
    ...options,
  });

export default useHomePage;
