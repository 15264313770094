import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import Box from '@components/elements/Box';
import Carousel from '@components/elements/Carousel/Carousel';
import ProductCard from '@components/elements/ProductCard/ProductCard';
import useMobileDetect from '@hooks/useMobileDetect';

import { useHomePageContext } from './HomePage.context';

const BestDishes = ({ bestDishes }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const currentDevice = useMobileDetect();
  const isMobile = currentDevice.isMobile();

  const {
    dishesQuery,
    dishImageBackground,
    dishImageBackgroundEnabled,
    dishImagePlaceholder,
    resources,
  } = useHomePageContext();

  if (!bestDishes?.enabled) {
    return null;
  }

  return (
    <div className="container" tw="my-4 sm:my-10 md:my-16">
      <Box>
        <h2>{bestDishes.title}</h2>
        <p
          tw="mb-8"
          dangerouslySetInnerHTML={{
            __html: bestDishes.content,
          }}
        />
        <Carousel
          className="swiper--home-page"
          options={{
            slidesPerView: 'auto',
            spaceBetween: 24,
            allowTouchMove: isMobile,
            ...(!isMobile
              ? {
                  keyboard: {
                    enabled: true,
                    onlyInViewport: false,
                  },
                }
              : {}),
            breakpoints: {
              480: {
                slidesPerView: 2,
                slidesPerGroup: 2,
              },
              992: {
                slidesPerView: 3,
                slidesPerGroup: 3,
              },
              1200: {
                slidesPerView: 4,
                slidesPerGroup: 4,
              },
              1400: {
                slidesPerView: 5,
                slidesPerGroup: 5,
              },
            },
          }}
        >
          {Slide => {
            return dishesQuery.data?.map(dish => (
              <Slide key={dish?.['@id']}>
                <ProductCard
                  {...{
                    rating: bestDishes.showRating ? dish?.rateAVG : undefined,
                    image: dish?.clientImage,
                    productLongName: dish?.nameForClient,
                    onButtonClick: () => router?.push(dish?.['@id']),
                    onCardClick: () => router?.push(dish?.['@id']),
                    buttonText: t('$*common.readMore', 'Czytaj więcej'),
                    dishImageBackground: dishImageBackground,
                    dishImageBackgroundEnabled: dishImageBackgroundEnabled,
                    dishImagePlaceholder: dishImagePlaceholder,
                    resources: resources,
                  }}
                />
              </Slide>
            ));
          }}
        </Carousel>
      </Box>
    </div>
  );
};

export default BestDishes;
