const ChevronUpIcon = ({ title = '', ...props }) => (
  <svg
    {...props}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0.08 143.05 310.99 167.95"
  >
    {title && <title>{title}</title>}
    <path d="M132.95 155.493c15.085-16.064 30.169-17.099 45.254.005l128.18 128.181c6.249 6.249 6.249 16.379 0 22.628-3.136 3.116-7.228 4.694-11.302 4.694-4.096 0-8.189-1.558-11.305-4.694L155.581 178.121 27.395 306.307a16.042 16.042 0 01-11.325 4.694c-4.097 0-8.193-1.558-11.306-4.694-6.248-6.249-6.248-16.379 0-22.628L132.95 155.493z" />
  </svg>
);

export default ChevronUpIcon;
